define([
        'modules/upx/collections/collection',
        '../models/PriceListType',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ProductsModule',
            object: 'PriceListType',
            collection_method: 'listPriceListTypes',
            model: Model
        });
    }
);