define([
        'modules/upx/models/model',
        './structs/AttributeSetAssign'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'BlogModule',

            object: 'AttributeSetAssign',
            setIds: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setAttributeSetAssignIds',parameters, ajaxOptions);
            },
            setAttributeSetAssign: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setAttributeSetAssign',parameters, ajaxOptions);
            }
        });
    }
);