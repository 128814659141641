define([
        'modules/upx/models/model',
        './structs/Configuration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'CommerceModule',
            idAttribute: 'id',
            object: 'Configuration',
            setTrial: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setConfigurationTrial',parameters, ajaxOptions);
            },
            enableAddons: function (parameters, ajaxOptions) {
                return this.callObjectMethod('enableConfigurationAddons',parameters, ajaxOptions);
            },
            disableAddons: function (parameters, ajaxOptions) {
                return this.callObjectMethod('disableConfigurationAddons',parameters, ajaxOptions);
            },
            getShipyardAuth: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getConfigurationShipyardAuth',parameters, ajaxOptions);
            }
        });
    }
);