define([
        'modules/upx/models/model',
        './structs/ShopAddress'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'ShopAddress'
        });
    }
);