define([
        'modules/upx/models/model',
        './structs/DataField'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'RelationsModule',
            idAttribute: 'name',
            object: 'DataField',
            setDataField: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setDataField',parameters, ajaxOptions);
            }
        });
    }
);