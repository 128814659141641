define([
        'modules/upx/collections/collection',
        '../models/ShopType',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ShopModule',
            object: 'ShopType',
            collection_method: 'listShopTypes',
            model: Model
        });
    }
);