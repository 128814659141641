define([
        'modules/upx/models/model',
        './structs/FlatProductByLanguage'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ProductsModule',

            object: 'FlatProductByLanguage',
            getDatasheetUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getFlatProductByLanguageDatasheetUrl',parameters, ajaxOptions);
            }
        });
    }
);