define([
        'modules/upx/models/model',
        './structs/ShopFlatProductByLanguage'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'ShopFlatProductByLanguage',
            getDatasheetUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getShopFlatProductByLanguageDatasheetUrl',parameters, ajaxOptions);
            }
        });
    }
);