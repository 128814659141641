define([
        'modules/upx/models/model',
        './structs/Attribute'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'BlogModule',
            idAttribute: 'id',
            object: 'Attribute',
            newMultiple: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newMultipleAttributes',parameters, ajaxOptions);
            },
            setOptions: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setAttributeOptions',parameters, ajaxOptions);
            },
            checkDeleteErrors: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkAttributeDeleteErrors',parameters, ajaxOptions);
            },
            deleteUnusedOptions: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deleteUnusedAttributeOptions',parameters, ajaxOptions);
            }
        });
    }
);