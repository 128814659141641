define([
        'modules/upx/collections/collection',
        '../models/TranslatedAttributeSet',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'BlogModule',
            object: 'TranslatedAttributeSet',
            collection_method: 'listTranslatedAttributeSets',
            model: Model
        });
    }
);